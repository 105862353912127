import Home from "pages/Home";
import Eventi from "pages/Eventi";
import Storia from "pages/Storia";
import Contatti from "pages/Contatti";

import {
	home,
	eventiPage,
	serviziPage,
	contattiPage,
	storiaPage,
} from "site-structure.js";

const appname = process.env.REACT_APP_WEBSITE_NAME;

const routes = [
	{
		key: "home",
		...home,
		component: <Home title={`${home.pageTitle} - ${appname}`} />,
		visible: true,
	},
	// {
	// 	key: "eventi",
	// 	...eventiPage,
	// 	component: <Eventi title={`${eventiPage.pageTitle} - ${appname}`} />,
	// 	visible: true,
	// },
	{
		key: "servizi",
		...serviziPage,
		component: <Eventi title={`${serviziPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "storia",
		...storiaPage,
		component: <Storia title={`${storiaPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "contatti",
		...contattiPage,
		component: <Contatti title={`${contattiPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
];

export { routes };
