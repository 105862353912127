import React from "react";

import { eventi } from "site-structure";

const Eventi = () => {
	return (
		<section className="module">
			<div className="container">
				<div className="row">
					<div className="col-sm-2 col-sm-offset-5">
						<div className="alt-module-subtitle">
							<span className="holder-w"></span>
							<h5 className="font-serif">Eventi</h5>
							<span className="holder-w"></span>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-8 col-sm-offset-2">
						<h2 className="module-title font-alt">Scoprici anche fuori Rabarbaro</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-8 col-sm-offset-2">
						<div className="row multi-columns-row mb-0">
							<div className="col-sm-6 col-md-6 col-lg-6">
								<div className="content-box">
									<div className="content-box-image">
										<img src={eventi[0].image} alt={eventi[0].name} />
									</div>
									<h3 className="content-box-title font-serif">{eventi[0].name}</h3>
									{eventi[0].description}
								</div>
							</div>
							<div className="col-sm-6 col-md-6 col-lg-6">
								<div className="content-box">
									<div className="content-box-image">
										<img src={eventi[1].image} alt={eventi[1].name} />
									</div>
									<h3 className="content-box-title font-serif">{eventi[1].name}</h3>
									{eventi[1].description}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Eventi;
