import React, { useEffect } from "react";
import Servizi from "components/Servizi";
import Piatti from "components/Piatti";
import EventiComponent from "components/Eventi";

import headerBg from "images/about_bg.jpg";

import { serviziPage } from "site-structure";

const Eventi = ({ title }) => {
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<>
			<section
				className="module module-big bg-dark-30"
				style={{
					backgroundImage: `url(${headerBg})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
				}}>
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{serviziPage.pageTitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt mb-0">{serviziPage.description}</h2>
						</div>
					</div>
				</div>
			</section>

			<Servizi />

			<Piatti />

			<EventiComponent />
		</>
	);
};

export default Eventi;
