import { render } from "@testing-library/react";
import React from "react";
import { Link } from "react-router-dom";

import { routes } from "routes";
import { contatti } from "site-structure";

import badge from "images/bi/rabarbaro_badge1_RGB_azzurro_blu.png";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

import "./footer.scss";

const Footer = () => {
	const renderRoutes = () =>
		routes.map((route) => {
			// se è una route con figli
			if (route.visible) {
				return (
					<>
						<li key={route.key + "-footer"}>
							<Link to={route.route} key={route.key + "-footer-link"}>
								{route.linkName}
							</Link>
						</li>
					</>
				);
			}

			return null;
		});

	return (
		<>
			<div className="module-small bg-dark footerContainer">
				<div className="container">
					<div className="row">
						<div className="col-sm-4">
							<div className="widget">
								<h5 className="widget-title font-alt">{process.env.REACT_APP_WEBSITE_NAME}</h5>
								<strong>{contatti.addressSpecifics}</strong>
								<p>{contatti.address}</p>
								<p>
									Telefono: <a href={"tel:" + contatti.phone}>{contatti.phone}</a>
								</p>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="widget">
								<h5 className="widget-title font-alt">&nbsp;</h5>
								<ul className="icon-list">{renderRoutes()}</ul>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="widget">
								<h5 className="widget-title font-alt">Legal</h5>
								<ul className="icon-list">
									<li>
										<a
											href="https://www.iubenda.com/privacy-policy/65441201"
											target="_blank"
											class="iubenda-black iubenda-noiframe"
											title="Privacy Policy">
											Privacy Policy
										</a>
									</li>

									<li>
										<a
											target="_blank"
											href="https://www.iubenda.com/privacy-policy/65441201/cookie-policy"
											class="iubenda-black iubenda-noiframe"
											title="Cookie Policy ">
											Cookie Policy
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div className="footer-logo">
							<ScrollAnimation animateIn="fadeIn" delay={100}>
								<img src={badge} />
							</ScrollAnimation>
						</div>
					</div>
				</div>
			</div>
			<hr className="divider-d" />
			<footer className="footer bg-dark">
				<div className="container">
					<div className="row">
						<div className="col-sm-9">
							<p className="copyright">
								&copy; {new Date().getFullYear()}&nbsp;
								<span style={{ opacity: 0.5, fontWeight: "regular" }}>Mood Srls</span>, Cf/PIVA
								01690120330, All Rights Reserved. Made by{" "}
								<a href="https://www.dojoagency.it" target="_blank">
									Dojo Agency
								</a>
							</p>
						</div>
						<div className="col-sm-3">
							<div className="footer-social-links">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.facebook.com/tavernarabarbaro/?locale=it_IT">
									<i className="fa fa-facebook"></i>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.instagram.com/tavernarabarbaro/">
									<i className="fa fa-instagram"></i>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.tripadvisor.it/Restaurant_Review-g187805-d11726255-Reviews-Rabarbaro-Piacenza_Province_of_Piacenza_Emilia_Romagna.html">
									<i className="fa fa-tripadvisor"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
