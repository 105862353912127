import smart_lunch from "images/smart_lunch.jpg";
import carte from "images/carte.jpg";

import catering from "images/catering.jpg";
import homecooking from "images/homecooking.jpg";

import piatto1 from "images/piatto1.jpg";
import piatto2 from "images/piatto2.jpg";
import piatto3 from "images/piatto3.jpg";
import piatto4 from "images/piatto4.jpg";
import piatto5 from "images/piatto5.jpg";
import piatto7 from "images/piatto7.jpg";
import piatto8 from "images/piatto8.jpg";
import piatto9 from "images/piatto9.jpg";

const home = {
	pageTitle: "Home",
	description: "Home",
	section: "Home",
	linkName: "Home",
	route: "/home",
};

const heroCarousel = [
	{
		title: "Rabarbaro",
		subtitle: "Tutto. Cambia. Sempre.",
		callToAction: "Prenota un tavolo",
		action:
			"https://api.whatsapp.com/send?phone=3335468741&amp;text=Ciao,vorrei%20prenotare%20un%20tavolo.",
	},
	{
		title: "Rabarbaro",
		subtitle: "Lavanderia di cucina confortevole.",
		callToAction: "Prenota un tavolo",
		action:
			"https://api.whatsapp.com/send?phone=3335468741&amp;text=Ciao,vorrei%20prenotare%20un%20tavolo.",
	},
];

const serviziSection = {
	title: "I nostri servizi",
	subtitle: "A tua scelta",
};

const servizi = [
	{
		key: "servizio1",
		image: smart_lunch,
		name: "Pranzo in lavanderia?!",
		when: "Dal Martedì al Venerdì, a pranzo.",
		shortDescription:
			"Pranza con le nostre proposte smart lunch 15, 25 e tasting lunch.",
		longDescription:
			"<ul><li>Smart Lunch 15 euro</li><li>Acqua, calice di vino, entrée con pane e focaccia fatti in casa, un piatto a scelta dal menù che cambia giornalmente e viene detto a voce, insalatina di frutta e piccolo dolce, caffè</li></ul><ul><li>Smart Lunch 25 euro</li><li>Acqua, calice di vino, entrée con pane e focaccia fatti in casa, due piatti del	menù detto a voce che cambia giornalmente, insalatina di frutta e piccolo dolce, caffè</li></ul><ul><li>Tasting Lunch</li><li>Piccolo menù degustazione a sorpresa che attinge ai piatti della carta serale	proponendo tapas, primo, secondo, dolce	</li><li>Vino signature RBR BOLLICINA o RBR GUTTURNIO BARRICA 2016</li></ul>",
		animation: "fadeInLeft",
	},
	{
		key: "servizio2",
		image: carte,
		name: "Cena in lavanderia?!",
		when: "Dal Martedì al Venerdì, a cena. Sabato, tutto il giorno",
		shortDescription:
			"Il nostro menù cambia ogni settimana in base al mercato e alla stagionalità.",
		longDescription:
			'<ul><li><span class="title">Tutto. Cambia. Sempre.</span>Anche il nostro menù.<br/>Oggi è così, domani chissà.</li><li><br/>Oltre alla carta, tre percorsi per chi non ha paura di farsi sorprendere:</li><li>GOLDENRBR – Lusso? No. Oro? Nemmeno. Solo piatti che brillano da soli.</li><li>TRADIZIONE RIVISTA. Un po’. – Tipo la nonna, ma con il Wi-Fi.</li><li>PERCORSO VEGAN – Niente facili battute. Solo roba buona, verde e tosta.</li><li>Mangi, ridi, torni. E sarà tutto diverso.</li></ul>',
		animation: "fadeInRight",
	},
];

const reviewsSection = {
	title: "La voce dei clienti",
	subtitle: "Dicono di noi",
};

const reviews = [
	{
		key: "review1",
		author: "Realizzo SRLS",
		date: new Date(2024, 1, 12).toLocaleDateString(),
		description:
			"Una fantastica scoperta nel cuore di Piacenza. Ambiente rivisitato e raffinato. Piatti degni di stelle Michelin a prezzi più che onesti. Abbiamo pranzato di sabato parcheggiando paraticanente a pochi metri. Ottima esperienza culinaria. Complimenti sia agli chef che a tuttO lo staff di sala. Torneremo sicuramente con amici",
	},
	{
		key: "review2",
		author: "Davide Pessina",
		date: new Date(2023, 8, 20).toLocaleDateString(),
		description:
			"Taverna Rabarbaro è una realtà giovane e dinamica in una città dove l’offerta culinaria è sempre troppo tradizionalista. Propongono una cucina contemporanea di piatti ben presentati e con ingredienti ricercati, che soddisfa gli occhi ed il palato. Il locale è arredato originalmente, ricavato in una ex lavanderia e da quel senso di intimità che non si trova spesso. Ottima e alternativa selezione di amari, rhum e whisky per concludere.",
	},
	{
		key: "review3",
		author: "Niccolò Cordani",
		date: new Date(2023, 4, 7).toLocaleDateString(),
		description:
			"Piatti sempre azzeccati sia come qualità che come gusto. Servizio estremamente cordiale, scherzoso per una serata informale e altrettanto educata. Location ricercata e stilosa. Torneremo sicuramente…",
	},
	{
		key: "review4",
		author: "Andrea Baricchi",
		date: new Date(2022, 11, 3).toLocaleDateString(),
		description:
			"Il nostro menù cambia ogni settimana in base al mercato e alla stagionalità. Le costanti sono la pasta fresca fatta in casa, varietà di carne, pesce, vegetariano e scelte vegan.",
	},
];

const piattiSection = {
	title: "Se sì, non abbiamo fatto apposta",
	subtitle: "Li abbiamo fatti noi?",
};

const piatti = [
	{
		name: "Piatto 2",
		image: piatto2,
		description: "Consectetur adipising elit",
	},
	{
		name: "Piatto 3",
		image: piatto3,
		description: "Sed do eiusmod",
	},
	{
		name: "Piatto 4",
		image: piatto4,
		description: "Tempor incididunt ut labore",
	},
	{
		name: "Piatto 1",
		image: piatto5,
		description: "Leo urna molestie at elementum eu",
	},
	{
		name: "Piatto 2",
		image: piatto7,
		description: "Nibh cras pulvinar mattis nunc sed blandit",
	},
	{
		name: "Piatto 3",
		image: piatto8,
		description: "Hac habitasse platea dictumst quisque",
	},
	{
		name: "Piatto 4",
		image: piatto9,
		description: "Tempor commodo ullamcorper a lacus",
	},
	{
		name: "Piatto",
		image: piatto1,
		description: "Lorem ipsum dolor sit amet",
	},
];

const eventiPage = {
	pageTitle: "I nostri eventi",
	description: "Catering, rinfreschi, matrimoni e homecooking.",
	section: "Eventi",
	linkName: "Eventi",
	route: "/eventi",
};

const serviziPage = {
	pageTitle: "I nostri servizi",
	description: "Pranzo, cena ed eventi privati.",
	section: "Servizi",
	linkName: "Servizi",
	route: "/servizi",
};

const eventi = [
	{
		name: "Catering e fingerfood",
		description:
			"Rendi grande ogni occasione con il nostro catering, richiedi un preventivo per il tuo evento.",
		image: catering,
	},
	{
		name: "Homecooking",
		description: "Tutto quello che ti piace del Rabarbaro, a casa tua.",
		image: homecooking,
	},
];

const storiaPage = {
	pageTitle: "Storia",
	description: "Scopri la storia di Rabarbaro",
	section: "Storia",
	linkName: "Storia",
	route: "/storia",
};

const storia = {
	title: "",
	text:
		"Rabarbaro - Laundry Bistrot - è una Ex lavanderia industriale; L’insegna ”Tintoria” rimane nella Piccola Saletta, dove un tempo si lavoravano i colori. La Scritta “Lavanderia” invece, si trova all’ingresso, dove il signor Paolo accoglieva i clienti assieme alla moglie Elda. Affianco, la sala principale, in origine “deposito” di indumenti appesi sulle serpentine. La cucina odierna del Bistrot era il fulcro dell’attività, con enormi lavatrici, ferri da stiro, macchine a vapore, tinozze per panni e assi di marmo, ancora oggi esposti e utilizzati, memori di un passato non dimenticato. Oltre che essere un attività storica, è stata per molti abitanti della piazza e del quartiere luogo di ritrovi, feste e incontri nel retrobottega, il cortiletto interno. Rabarbaro vuole continuare questa novella, cucinando dove prima si lavava e sbicchierando dove prima si stirava e si rinvigoriva un colore di un abito da festa mondana, conservando e accudendo quella convivialità che da sempre fa parte di questo piccolo spazio. Rabarbaro è storia, è cambiamento radicato nel buono e sincero. Com’era. Com’è.",
};

const contattiPage = {
	pageTitle: "Contatti",
	description: "Informazioni e richieste",
	section: "Contatti",
	linkName: "Contatti",
	route: "/contatti",
};

const contatti = {
	email: "tavernarabarbaro@gmail.com",
	phone: "+39 333 54 68 741",
	address: "Piazza Cittadella 9, Piacenza (PC)",
	addressSpecifics: "In pieno centro, di fronte a Palazzo Farnese.",
};

export {
	home,
	heroCarousel,
	serviziSection,
	servizi,
	piattiSection,
	piatti,
	reviewsSection,
	reviews,
	eventiPage,
	eventi,
	serviziPage,
	storiaPage,
	storia,
	contattiPage,
	contatti,
};
